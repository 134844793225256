const mapDataCoordinateSystem = {
  center: [1834937.816028031, 6678718.07588299],
  epsg: 'EPSG:3857',
  srid: 3857,
  verboseName: 'Google Mercator',
};

const mapDefaultZoom = 9;
const mapDefaultMinZoom = 0;
const mapDefaultMaxZoom = 28;

const appName = 'Legnicka Specjalna Strefa Ekonomiczna';
const contactData = {
  name: 'Justyna Hubiak',
  phone: '+48 537 770 954',
  mail: 'hubiak@lsse.eu',
};
const defaultLanguage = 'pl';
const languages = ['pl', 'en'];

export {
  mapDataCoordinateSystem,
  mapDefaultZoom,
  mapDefaultMinZoom,
  mapDefaultMaxZoom,
  appName,
  contactData,
  defaultLanguage,
  languages,
};
