import Vue from 'vue';

import router from '@/router';
import store from '@/store';
import { languages } from '@/assets/js/variables';
import '@/assets/styles/_style.scss';

import dayjs from '@/plugins/dayjs';
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import utility from '@/plugins/utility';
import variables from '@/plugins/variables';

import App from '@/App.vue';

Vue.config.productionTip = false;

Vue.use(dayjs);
Vue.use(utility);
Vue.use(variables);

(() => {
  languages.forEach(async lng => {
    const file = await import(/* webpackExclude: /\.map/ */ `vuetify/es5/locale/${lng}`);
    vuetify.preset.lang.locales[lng] = file.default;
  });
})();

new Vue({
  name: 'mainJs',
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app');
