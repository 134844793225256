import * as variables from '@/assets/js/variables';
import colors from '@/assets/styles/_export.scss';

const attachVariables = (Vue, variables, wrappingObject, excludedVariables = []) => {
  const filteredKeys = Object.keys(variables).filter(key => !excludedVariables.includes(key));
  const object = filteredKeys.reduce((total, key) => {
    return Object.defineProperty(total, key, {
      value: Object.freeze(variables[key]),
    });
  }, {});
  Object.assign(Vue.prototype, { [`$_${wrappingObject}`]: object });
};

export default {
  install(Vue) {
    attachVariables(Vue, variables, 'config');
    attachVariables(Vue, colors, 'colors');
  },
};
