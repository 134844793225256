import Vue from 'vue';
import Vuex from 'vuex';
import pathify from 'vuex-pathify';

import authentication from '@/store/authentication';
import admin from '@/store/admin';
import featuresData from '@/store/featuresData';
import map from '@/store/map';
import snackbar from '@/store/snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [pathify.plugin],
  modules: { authentication, admin, featuresData, map, snackbar },
});
