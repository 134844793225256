<template>
  <v-app id="app" class="fill-height">
    <v-main>
      <template v-if="isFetched">
        <router-view />
      </template>
      <main-loader v-else />
      <the-snackbar />
    </v-main>
  </v-app>
</template>

<script>
import { call } from 'vuex-pathify';

import TheSnackbar from '@/components/shared/TheSnackbar';
import MainLoader from '@/components/shared/MainLoader';

export default {
  name: 'App',
  components: { TheSnackbar, MainLoader },
  data: () => ({
    isFetched: false,
  }),
  computed: {
    currentLng() {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLng(nV) {
      if (!nV) return;
      localStorage.setItem('lng', nV);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    getSettings: call('admin/getSettings'),
    async init() {
      document.title = this.$_config.appName;
      const lng =
        this.$_config.languages.find(language => language === localStorage.getItem('lng')) ||
        this.$_config.languages.find(language => window.navigator.language.startsWith(language)) ||
        this.$_config.defaultLanguage;
      this.$i18n.locale = lng;
      this.$vuetify.lang.current = lng;
      this.$dayjs.locale(lng);
      await this.getSettings();
      this.isFetched = true;
    },
  },
};
</script>
